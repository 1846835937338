import "./App.css"
import Calendar from "./Components/Calendar"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Calendar />
      </header>
    </div>
  )
}

export default App
