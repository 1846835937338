import React, { useState, useRef, useEffect } from "react"
import {
  Form,

  TextArea,

  Button,

} from "semantic-ui-react"
import "../App.css"
import { DarkModeSwitch } from 'react-toggle-dark-mode';
const Calendar = () => {
  const [showPopup, setShowPopup] = useState(false)
  const [selectedBox, setSelectedBox] = useState(null)
  const [hoveredBox, setHoveredBox] = useState(null)
  const [selectedMonth, setSelectedMonth] = useState(null)
  const [title, setTitle] = useState("")
  const [items, setItems] = useState([])
  const [newItem, setNewItem] = useState("")
  const [jsonnotes, setJsonNotes] = useState([])
  const [notes, setNotes] = useState("")
  const [notesList, setNotesList] = useState([])
  const [status, setStatus] = useState("")
  const [sticky, setSticky] = useState(true)

  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };
  



  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.pageYOffset > 0)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  const ref = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setSelectedMonth(null)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

  const noteForDay = []

  useEffect(() => {
    const savedNotes = localStorage.getItem("notes")

    if (savedNotes) {
      const notesObject = JSON.parse(savedNotes)
      const toarray = Object.values(notesObject)
      const selectedNotes = toarray.find((note) => note.id === selectedBox)
      setJsonNotes(selectedNotes)
      setNotesList(toarray)
      if (selectedNotes) {
        setNotes(selectedNotes)
      } else {
        setNotes("")
      }
    }
  }, [selectedBox])

  const handleSave = () => {
    const data = {
      id: selectedBox,
      header: header,
      richText: richText,
      status: status,
    }
    setIsSidebarOpen(false)

    const savedNotes = localStorage.getItem("notes")
    let notesObject = {}
    if (savedNotes) {
      notesObject = JSON.parse(savedNotes)
    }

    notesObject[selectedBox] = data

    localStorage.setItem("notes", JSON.stringify(notesObject))
  }

  const handleDelete = () => {
    const savedNotes = localStorage.getItem("notes")
    let notesObject = {}
    if (savedNotes) {
      notesObject = JSON.parse(savedNotes)
    }

    delete notesObject[selectedBox]
    setIsSidebarOpen(false)

    localStorage.setItem("notes", JSON.stringify(notesObject))
  }

  for (let i = 0; i < 365; i++) {
    if (jsonnotes && jsonnotes.id === i + 1) {
      noteForDay.push(jsonnotes)
    }
  }

  const boxes = []
  for (let i = 1; i <= 366; i++) {
    const date = new Date(2024, 0, i)
    const month = date.getMonth()
    const day = date.getDay()
    const dayofMonth = date.getDate()
    boxes.push(
      <div
        className="box"
        key={i}
        onClick={() => {
          setShowPopup(true)
          setSelectedBox(i)
          setSelectedMonth(month)
          toggleSidebar()
        }}
        onMouseEnter={() => {
          setHoveredBox(i)
          setSelectedMonth(month)
        }}
        onMouseLeave={() => {
          setHoveredBox(null)
          setSelectedMonth(month)
        }}
        onContextMenu={(e) => {
          e.preventDefault()
          setSelectedBox(i)
        }}
        style={{
          backgroundColor:
            notesList.find((note) => note.id === i) &&
            notesList.find((note) => note.status === "done")
              ? "#D2001A"
              : hoveredBox
              ? new Date(2024, 0, hoveredBox).getMonth() === month
                ? "#0E5E6F"
                : selectedMonth === month
                ? "#0E5E6F"
                : "#EFF5F5"
              : selectedMonth === month
              ? "#0E5E6F"
              : "#EFF5F5",
          color: hoveredBox
            ? new Date(2024, 0, hoveredBox).getMonth() === month
              ? "white"
              : selectedMonth === month
              ? "white"
              : "black"
            : selectedMonth === month
            ? "white"
            : "black",
          borderColor:
            notesList.find((note) => note.id === i) &&
            notesList.find((note) => note.status === "done")
              ? "#D2001A"
              : hoveredBox
              ? new Date(2024, 0, hoveredBox).getMonth() === month
                ? "#0E5E6F"
                : selectedMonth === month
                ? "#0E5E6F"
                : "#EFF5F5"
              : selectedMonth === month
              ? "#0E5E6F"
              : "#EFF5F5",
          borderRadius: "4px",
          width: "100px",
          height: "70px",
          display: "flex",
          margin: "5px",
          fontSize: "14px",
          fontStyle: "italic",
          fontFamily: "Arial, Helvetica, sans-serif",
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
          verticalAlign: "middle",
          cursor: "pointer",
          "&:hover": {
            border: "5px solid white",
          },
        }}
      >
       
        <div
          style={{ marginLeft: "3px", fontSize: "13px", fontWeight: "bolder" }}
        >
          {" "} {i}{" "} 
          {days[day]} 
         
        </div>
        <div style={{ marginLeft: "3px", marginTop: "10px", fontSize: "8px", opacity: "60%" }}> Day {dayofMonth} </div>
        {/* Add an icon if there is a note for this day */}
        {noteForDay.find((note) => note.id === i) && (
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              right: "0px",
              fontSize: "10px",
              fontWeight: "bolder",
            }}
          >
            <i className="fas fa-sticky-note"></i>
          </div>
        )}
      </div>
    )
  }

  const addItem = () => {
    if (newItem) {
      setItems([...items, { id: Date.now(), text: newItem, completed: false }])
      setNewItem("")
    }
  }

  const toggleCompleted = (id) => {
    setItems(
      items.map((item) => {
        if (item.id === id) {
          return { ...item, completed: !item.completed }
        }
        return item
      })
    )
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const sidebarRef = useRef(null)

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  useEffect(() => {
    if (sidebarRef.current) {
      if (isSidebarOpen) {
        sidebarRef.current.style.animation = "slideIn 500ms ease-in-out"
      } else {
        sidebarRef.current.style.animation = "slideOut 500ms ease-in-out"
      }
    }
  }, [isSidebarOpen])

  const [header, setHeader] = useState("")
  const [richText, setRichText] = useState("")
  const [checkboxes, setCheckboxes] = useState([])

  const handleHeaderChange = (event) => {
    setHeader(event.target.value)
    setJsonNotes({
      ...jsonnotes,
      header: event.target.value,
    })
  }

  const handleRichTextChange = (event) => {
    setRichText(event.target.value)
    setJsonNotes({
      ...jsonnotes,
      richText: event.target.value,
    })
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
    setJsonNotes({
      ...jsonnotes,
      status: event.target.value,
    })
  }

  const handleMonthSelection = (index) => {
    setSelectedMonth(index)
  }

  // function HamburgerMenu() {
  //   const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  //   return (
  //     <a
  //       onClick={() => setIsSidebarOpen(isSidebarOpen)}
  //       role="button"
  //       aria-label="Menu"
  //       tabIndex={0}
  //       style={{
  //         display: `${window.innerWidth <= 865 ? "block" : "none"}`,
  //         width: "200px",
  //         height: "200px",
  //         fontSize: "30px",
  //         marginTop: "10px",
  //       }}
  //     >
  //       ☰
  //     </a>
  //   )
  // }

  return (
    <center>
      <div
        style={{ display: "flex", justifyContent: "center", minWidth: "400px",  background: darkMode ? "black" : "white" }}
      >
        <div className="year-vision-container">
          <div className="websitename">
            <div style={{ color: !darkMode ? "black" : "white" }}> YearVision </div>
            <span style={{ fontSize: "10px", color: !darkMode ? "black" : "white" }}> 2024 </span>
          </div>

          {months.map((month, index) => (
            <button
              key={month}
              onClick={() => setSelectedMonth(index)}
              className={
                selectedMonth === index
                  ? "selected-month-button"
                  : "month-button"
              }
            >
              {month}
            </button>
            
          ))}
          <DarkModeSwitch
      style={{marginLeft: "5px", marginTop: "12px", marginRight: "3px",  }}
      checked={darkMode}
      onChange={toggleDarkMode}
      size={25}
    />
        </div>
        {/* <HamburgerMenu /> */}
        <div className="box-grid">{boxes}</div>
        <div
          ref={sidebarRef}
          className="sidebar"
          style={{ left: isSidebarOpen ? "0" : "-400px" }}
        >
          <Form className="ui-form">
            <Form.Field>
              <input
                value={jsonnotes ? jsonnotes.header : ""}
                defaultValue={jsonnotes ? jsonnotes.header : ""}
                placeholder="Title..."
                onChange={handleHeaderChange}
                className="title-input"
              />
              <hr />
            </Form.Field>
            <Form.Field>
              <TextArea
                value={jsonnotes ? jsonnotes.richText : ""}
                defaultValue={jsonnotes ? jsonnotes.richText : ""}
                placeholder="Note..."
                onChange={handleRichTextChange}
                className="note-textarea"
              />
              <hr />
            </Form.Field>
            <Form.Field>
              <select
                value={jsonnotes ? jsonnotes.status : ""}
                defaultValue={jsonnotes ? jsonnotes.status : ""}
                placeholder="Status..."
                onChange={handleStatusChange}
                className="status-input"
              >
                <option value="none">Status</option>
                <option value="todo">To Do</option>
                <option value="inprogress">In Progress</option>
                <option value="done">Done</option>
              </select>
              <hr />
            </Form.Field>

            <Button className="save-button" onClick={handleSave}>
              Save
            </Button>
            <Button className="delete-button" onClick={handleDelete}>
              Delete
            </Button>
          </Form>
        </div>
      </div>
    </center>
  )
}

export default Calendar
